import React, { type ReactNode } from 'react'

interface ISectionBoxProps {
  children?: ReactNode
  className?: string
  [key: string]: unknown;
}

export const SectionBox: React.FC<ISectionBoxProps> = ({
  children,
  className,
  ...rest
}: ISectionBoxProps) => {
  return (
    <div className={`govuk-grid-row ${className}`} {...rest}>
      <div className="govuk-grid-column-full">{children}</div>
    </div>
  )
}

export default SectionBox
